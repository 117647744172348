import { CubeTextureLoader, CubeTexture } from 'three';
import { LoadingManagerWrapper } from './loading-manager';

const loader = new CubeTextureLoader(
  LoadingManagerWrapper.getInstance().manager,
);

export class CubeMap {
  constructor(public source: string, public texture: CubeTexture) {}

  public static async load(base: string): Promise<CubeMap> {
    return new Promise((resolve, reject) =>
      loader.load(
        [
          `${base}/left.png`, // pos-x
          `${base}/right.png`, // neg-x
          `${base}/top.png`, // pos-y
          `${base}/bottom.png`, // neg-y
          `${base}/front.png`, // pos-z
          `${base}/back.png`, // neg-z
        ],
        (texture) => resolve(new CubeMap(base, texture)),
        undefined,
        reject,
      ),
    );
  }
}
