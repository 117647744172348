import { ImageLoader } from 'three';
import { WorldLoader } from '../../../common/world/world-loader';
import { CanvasUtils } from '../canvas-utils';
import { LoadingManagerWrapper } from '../resource/loading-manager';

const loader = new ImageLoader(LoadingManagerWrapper.getInstance().manager);
const canvasUtil = new CanvasUtils();
const worldPath = '/assets/terrain/region';

export class ClientWorldLoader implements WorldLoader {
  async loadHeightMap(
    chunkX: number,
    chunkY: number,
    scale: number,
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      loader.load(
        `${worldPath}/height-${chunkX}-${chunkY}.png`,
        (data) => resolve(canvasUtil.readPixelDataRScaled(data, scale)),
        undefined,
        (err) => {
          reject(err);
        },
      );
    });
  }
}
