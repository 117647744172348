import { Color, ShaderMaterial, UniformsLib, UniformsUtils } from 'three';
import { BaseTexture } from '../resource/texture';

export const vertex = /* glsl */ `
varying vec3 vViewPosition;
varying vec2 vUv;
#include <common>
#include <uv_pars_vertex>
#include <uv2_pars_vertex>
#include <displacementmap_pars_vertex>
#include <envmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <normal_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>
void main() {
  vUv = uv;
	#include <uv_vertex>
	#include <uv2_vertex>
	#include <color_vertex>
	#include <beginnormal_vertex>
	#include <morphnormal_vertex>
	#include <skinbase_vertex>
	#include <skinnormal_vertex>
	#include <defaultnormal_vertex>
	#include <normal_vertex>
	#include <begin_vertex>
	#include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>
	#include <project_vertex>
	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	vViewPosition = - mvPosition.xyz;
	#include <worldpos_vertex>
	#include <envmap_vertex>
	#include <shadowmap_vertex>
	#include <fog_vertex>
}
`;

export const fragment = /* glsl */ `
uniform vec3 emissive;
uniform vec3 specular;
uniform float shininess;

uniform sampler2D baseT;
uniform sampler2D maskT;
uniform vec3 colorMask;

varying vec2 vUv;
#include <common>
#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <alphatest_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_pars_fragment>
#include <cube_uv_reflection_fragment>
#include <fog_pars_fragment>
#include <bsdfs>
#include <lights_pars_begin>
#include <normal_pars_fragment>
#include <lights_phong_pars_fragment>
#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment>
#include <specularmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>
void main() {
	#include <clipping_planes_fragment>

  vec4 baseTex = texture2D(baseT, vUv);
  vec4 maskTex = texture2D(maskT, vUv);
  vec3 combine = mix(baseTex.rgb, colorMask, maskTex.r);

	vec4 diffuseColor = vec4( combine, 1.0 );
	ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
	vec3 totalEmissiveRadiance = emissive;
	#include <logdepthbuf_fragment>
	#include <map_fragment>
	#include <color_fragment>
	#include <alphamap_fragment>
	#include <alphatest_fragment>
	#include <specularmap_fragment>
	#include <normal_fragment_begin>
	#include <normal_fragment_maps>
	#include <emissivemap_fragment>
	// accumulation
	#include <lights_phong_fragment>
	#include <lights_fragment_begin>
	#include <lights_fragment_maps>
	#include <lights_fragment_end>
	// modulation
	#include <aomap_fragment>
	vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
	#include <envmap_fragment>
	#include <output_fragment>
	#include <tonemapping_fragment>
	#include <encodings_fragment>
	#include <fog_fragment>
	#include <premultiplied_alpha_fragment>
	#include <dithering_fragment>
}
`;

let instance: PonyEyes;

export class PonyEyes {
  private base!: BaseTexture;
  private mask!: BaseTexture;
  public shader!: ShaderMaterial;

  async initialize() {
    this.base = await BaseTexture.load('/assets/eyes/eye-base.png');
    this.mask = await BaseTexture.load('/assets/eyes/eye-mask.png');

    this.base.texture.flipY = false;
    this.mask.texture.flipY = false;
    this.shader = new ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      lights: true,
      uniforms: UniformsUtils.merge([
        UniformsLib.common,
        UniformsLib.specularmap,
        UniformsLib.envmap,
        UniformsLib.aomap,
        UniformsLib.lightmap,
        UniformsLib.emissivemap,
        UniformsLib.fog,
        UniformsLib.lights,
        {
          baseT: { value: this.base.texture },
          maskT: { value: this.mask.texture },
          colorMask: { value: new Color(0xff0000) },
          shininess: { value: 100 },
        },
      ]),
    });
  }

  public getShader(): ShaderMaterial {
    return this.shader.clone();
  }

  public setColor(
    shader: ShaderMaterial,
    color: number | string,
  ): ShaderMaterial {
    shader.uniforms.colorMask.value = new Color(color);
    return shader;
  }

  public static getInstance(): PonyEyes {
    if (!instance) {
      instance = new PonyEyes();
    }
    return instance;
  }
}
