import { TextureLoader, Texture } from 'three';
import { LoadingManagerWrapper } from './loading-manager';

const loader = new TextureLoader(LoadingManagerWrapper.getInstance().manager);

export class BaseTexture {
  constructor(public source: string, public texture: Texture) {}

  public static async load(src: string): Promise<BaseTexture> {
    return new Promise((resolve, reject) =>
      loader.load(
        src,
        (texture) => resolve(new BaseTexture(src, texture)),
        undefined,
        reject,
      ),
    );
  }
}
