import { Material, Object3D, Vector2, Vector3 } from 'three';
import { QuadtreeNode } from './quadtree-node';

export class QuadtreeMesher {
  public getHeight!: (x: number, y: number) => number;
  public getNormal!: (x: number, y: number) => Vector3;
  public root!: QuadtreeNode;
  public container = new Object3D();
  public actionsLeft = 1;
  public maxDepth = 1;

  constructor(
    public size: number,
    public position: Vector2,
    public material: Material,
  ) {}

  public update(camera: Vector3) {
    this.actionsLeft = 1;
    this.root?.update(camera);
  }

  public initialize() {
    this.root = new QuadtreeNode(this, null, 0, 0, this.position.clone());
  }
}
