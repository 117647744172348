import { CanvasTexture, Sprite, SpriteMaterial } from 'three';
import { CanvasUtils } from './canvas-utils';

export class ChatBubble {
  public tag!: Sprite;
  public width!: number;
  public height!: number;
  private texture!: CanvasTexture;
  private material!: SpriteMaterial;

  constructor(private builder: CanvasUtils, private message: string[]) {
    this.create();
  }

  create() {
    const { texture, width, height } = this.builder.createTextCanvas(
      this.message,
    );

    this.texture = texture;
    this.width = width;
    this.height = height;

    this.material = new SpriteMaterial({
      map: texture,
      transparent: true,
    });

    const label = new Sprite(this.material);

    const labelBaseScale = 0.01;
    label.scale.x = width * labelBaseScale;
    label.scale.y = height * labelBaseScale;

    this.tag = label;
  }

  dispose() {
    this.material.dispose();
    this.texture.dispose();
  }
}
