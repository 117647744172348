import { LoadingManager } from 'three';

let instance: LoadingManagerWrapper;
export class LoadingManagerWrapper {
  public manager = new LoadingManager();
  public element = document.createElement('div');
  private _inner = document.createElement('div');
  private _status = document.createElement('span');
  private _barWrapper = document.createElement('div');
  private _bar = document.createElement('div');
  private _assetsLoaded = false;
  private _connected = false;

  public static getInstance(): LoadingManagerWrapper {
    if (!instance) {
      instance = new LoadingManagerWrapper();
    }
    return instance;
  }

  public initialize() {
    this.element.classList.add('loading__wrapper');
    this._inner.classList.add('loading');
    this._status.classList.add('loading__status');
    this._barWrapper.classList.add('loading__bar');
    this._bar.classList.add('loading__bar-inner');

    this._barWrapper.append(this._bar);
    this._inner.append(this._status, this._barWrapper);
    this.element.append(this._inner);

    this._status.innerText = 'Loading (0 %)';
    this.manager.onProgress = (url, loaded, total) => {
      const percent = (loaded / total) * 100;
      this._status.innerText = `Loading (${Math.floor(percent)} %)`;
      this._bar.style.setProperty('--loading-percentage', `${percent}%`);
    };

    this.manager.onLoad = () => {
      this._assetsLoaded = true;
    };

    document.body.prepend(this.element);
  }

  public isConnecting() {
    if (this._connected) {
      return;
    }
    this._status.innerText = 'Connecting to server';
  }

  public connected() {
    this._connected = true;
    if (!this._assetsLoaded) {
      setTimeout(() => {
        this.connected();
      }, 1000);
      return;
    }
    this._status.innerText = 'Connected!';
    this.element.classList.add('loading--complete');
  }

  public showError(message: string) {
    this.element.classList.remove('loading--complete');
    this.element.classList.add('loading--error');
    this._status.innerText = message;
  }
}
