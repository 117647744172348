import { RepeatWrapping, Texture, TextureLoader } from 'three';
import { LoadingManagerWrapper } from '../resource/loading-manager';

const loader = new TextureLoader(LoadingManagerWrapper.getInstance().manager);

export class ClientWorldTexture {
  constructor(public source: string, public texture: Texture) {}

  public static async loadTexture(src: string): Promise<ClientWorldTexture> {
    const texture = await new Promise<Texture>((resolve, reject) => {
      const load = loader.load(src, resolve, undefined, reject);
    });

    const worldTexture = new ClientWorldTexture(src, texture);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    return worldTexture;
  }
}
