import {
  WorldManifest,
  WorldManifestRegion,
} from '../../../common/world/world-manifest';

export class ClientWorldManifest implements WorldManifest {
  constructor(
    public worldWidth: number,
    public worldHeight: number,
    public worldChunkSize: number,
    public worldHeightScale: number,
    public textureBombingNoise: string,
    public regionMap: WorldManifestRegion[],
  ) {}

  public static async loadManifest(): Promise<ClientWorldManifest> {
    const file = await fetch('/assets/terrain/manifest.json');
    const json = await file.json();
    const manifest = new ClientWorldManifest(
      json.worldWidth,
      json.worldHeight,
      json.worldChunkSize,
      json.worldHeightScale,
      json.textureBombingNoise,
      json.regionMap,
    );

    return manifest;
  }
}
